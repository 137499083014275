import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Category from '../components/category';
import Hero from '../components/hero';
import getPageImage from '../utils/getPageImage';

const ArtistCategory = (props) => {
  const { edges: categories } = props.data.allWordpressWpArtists;
  const { edges: artists } = props.data.allWordpressWpArtist;
  const { options } = props.data.allWordpressAcfOptions.edges[0].node;
  const { pageTitle, domain } = props.pageContext;
  const currentPage = getPageImage(options.domains, domain);
  const { featured_media: heroImage, categories_content: categoriesContent } = currentPage[0];

  return (
    <Layout>
      <SEO title={pageTitle || 'Burysax'} />

      <Hero
        title={pageTitle}
        image={heroImage.localFile ? heroImage.localFile.childImageSharp.fluid : null}
      />

      {categories && categories.map((categoryNode) => {
        const category = categoryNode.node;
        // Filter artists only from current category
        const categoryArtists = artists.filter(
          (artist) => artist.node.artists.includes(category.wordpress_id),
        );
        const customCategoryContent = categoriesContent.filter((content) => content.category.slug === category.slug);
        // eslint-disable-next-line prefer-destructuring, dot-notation
        category['customContent'] = customCategoryContent[0];

        return (
          <Category key={category.id} category={category} artists={categoryArtists} />
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  query artistCategory($categorySlugs: [String], $domain: String) {
    allWordpressAcfOptions(filter: {options: {domains: {elemMatch: {domain: {eq: $domain}}}}}) {
      edges {
        node {
          options {
            domains {
              domain
              page_title
              featured_media {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              categories_content {
                content
                category {
                  name
                  slug
                  term_id
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpArtists(filter: {slug: {in: $categorySlugs}}) {
      edges {
        node {
          id
          name
          description
          img
          path
          slug
          wordpress_id
          acf {
            image_position
            featured_media {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 640, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpArtist(
      filter: { polylang_current_lang: { eq: "cs_CZ" } },
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          id
          title
          path
          artists
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 640, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ArtistCategory;
