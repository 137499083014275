import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';

import Artists from './artists';
import Button from './button';

const Wrapper = styled(Box)`
  background-image: radial-gradient(ellipse at center,#1b135b 0, #020107 100%);
  color: #fff;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-position: ${(props) => (props.position ? 'center 20%' : 'center')};
  filter: blur(3px);
  transform: scale(1.1);

  @media screen and (min-width: 640px) {
    background-position: ${(props) => (props.position ? 'center 20%' : 'center')} !important;
  }
`;

const CategoryLink = styled.a`
  display: block;
  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: #FFF;
  font-size: 2rem;
  z-index: 100;
`;

const Content = styled(Box)`
  font-size: 1.2rem;
  line-height: 2;
`;

const Category = (props) => {
  const { category, artists } = props;
  const categoryLink = `https://www.burysax.cz${category.path}`;
  let content = category.description.replace(/<h1.+h1>/gim, '');

  if (Object.prototype.hasOwnProperty.call(category, 'customContent')) {
    content = category.customContent.content;
  }

  return (
    <Wrapper pt={5} pb={0}>
      {category.acf.featured_media && (
        <ImageWrapper width={['100%']} height="320px" mx="auto" mb={4} data-sal="slide-up" data-sal-easing="ease-in-out">
          {/* TODO: Fix position of background image */}
          <CategoryLink href={categoryLink}>
            <StyledBackgroundImage
              Tag="section"
              backgroundColor="#040e18"
              fluid={category.acf.featured_media.localFile.childImageSharp.fluid}
              alt={category.name}
              position={category.acf.image_position || null}
              style={{ height: '100%' }}
            />
            <Title>{category.name}</Title>
          </CategoryLink>
        </ImageWrapper>
      )}

      <Flex flexWrap="wrap" flexDirection="column" mb={4} px={3} key={category.id}>
        <Flex flexDirection="column" alignItems="center" width={['98%', '66%', '50%']} mx="auto">
          {/* Place text content but without h1 element */}
          <Content mb={4} dangerouslySetInnerHTML={{ __html: content }} data-sal="slide-up" data-sal-easing="ease-in-out" />

          <Button href={categoryLink} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in-out">
            Zobrazit více informací
          </Button>
        </Flex>
      </Flex>

      <Flex>
        <Artists artists={artists} />
      </Flex>
    </Wrapper>
  );
};

export default Category;
