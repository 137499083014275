import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { Flex, Heading, Text } from 'rebass/styled-components';

import logo from '../images/burysax-logo-white.svg';
import chevronDown from '../images/chevron-down.svg';

const StyledWrapper = styled.div`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

const HeroBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.5);
`;

const StyledLogo = styled.img`
  margin-bottom: 2rem;
  max-width: 80%;
`;

const StyledTitle = styled(Heading)`
  margin: 0;
  padding: 0;
  color: #FFF;
  /* font-size: 4rem; */
  text-align: center;
  z-index: 1;
`;

const ScrollDownWrapper = styled(Flex)`
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
`;

const ScrollDown = styled.a`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  color: #FFF;
  font-weight: bold;
  transform: translateY(-50%);
`;

const Arrow = styled.img`
  margin-top: 1rem;
`;

const Hero = (props) => {
  const { title, image } = props;

  return (
    <StyledWrapper>
      {image
        && (
          <StyledBackgroundImage
            Tag="div"
            fluid={image}
            alt={title}
          />
        )}

      <HeroBody>
        <StyledLogo src={logo} alt="Burysax" data-sal="slide-down" data-sal-easing="ease-in-out" />

        <StyledTitle fontSize={[5, 6, 7]} color="primary" data-sal="slide-down" data-sal-easing="ease-in-out" data-sal-delay="100">
          {title}
        </StyledTitle>

        <ScrollDownWrapper>
          <ScrollDown>
            <Text data-sal="slide-down" data-sal-easing="ease-in-out" data-sal-delay="250">Skrolujte dolů</Text>
            <Arrow src={chevronDown} alt="" data-sal="slide-down" data-sal-easing="ease-in-out" data-sal-delay="300" />
          </ScrollDown>
        </ScrollDownWrapper>
      </HeroBody>
    </StyledWrapper>
  );
};

export default Hero;
