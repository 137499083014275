import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';

const ArtistWrapper = styled(Flex)`
  background-color: rgba(0, 0, 0, 0.2);
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  transform: scale(1);
  transform-origin: center;
  transition: transform 800ms ease-out;
`;

const Wrapper = styled(Box)`
  position: relative;
  overflow: hidden;

  &:hover ${StyledBackgroundImage} {
    transform: scale(1.05);
  }
`;

const StyledLink = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 200ms ease-in-out;
  z-index: 1000;

  &:focus,
  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const Name = styled.h3`
  position: relative;
  color: #FFF;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
`;

const Artists = (props) => {
  const { artists } = props;

  return (
    <>
      {artists && (
        <ArtistWrapper width="100%" flexDirection={['column', 'row', 'row']} flexWrap="wrap" flexBasis="100%" justifyContent="center">
          {
            artists.map((artistNode, i) => {
              const artist = artistNode.node;

              return (
                <Wrapper key={artist.id} height="420px" flexBasis={['auto', '50%', '20%']} data-sal="slide-right" data-sal-delay={100 * i} data-sal-easing="ease-in-out">
                  {artist.featured_media.localFile && (
                  <StyledBackgroundImage
                    Tag="div"
                    fluid={artist.featured_media.localFile.childImageSharp.fluid}
                    alt={artist.title}
                    style={{ height: '100%', position: 'relative' }}
                  />
                  )}
                  <StyledLink href={`https://www.burysax.cz${artist.path}`}>
                    <Name>
                      {artist.title}
                    </Name>
                  </StyledLink>
                </Wrapper>
              );
            })
          }
        </ArtistWrapper>
      )}
    </>
  );
};

export default Artists;
