import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

const StyledButton = styled.a`
  padding: 0.75rem 1rem;
  cursor: pointer;
  background-color: #3425af;
  border: none;
  border-radius: 3rem;
  color: #fff;
  font-size: 1.25rem;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transition: background-color 200ms ease-out;
  white-space: normal;

  &:focus,
  &:hover {
    background-color: ${darken(0.1, '#3425af')};
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const Button = (props) => <StyledButton {...props}>{props.children}</StyledButton>;

export default Button;
